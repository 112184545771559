import React from "react";
import { Link } from "react-router-dom";

export default function Geriatric() {
  return (
    <div>
      <section className="page-section bg-primary">
        <div id="portfolio">
          <div className="container-fluid px-4 px-lg-5">
            <h2 className="text-center text-white">Geriatrik Rehabilitasyon</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Nedir?</div>
                  <div className="card-item">
                    Yaşlı bireylerde fizyolojik kayba veya çeşitli hastalıklara
                    bağlı gelişen fonksiyonel kayıpların önlenmesi,
                    geciktirilmesi, azaltılması amacı ile yapılan rehabilitasyon
                    hizmetlerinin tamamıdır.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Geriatrik Rehabilitasyonun İlkeleri Nedir?
                  </div>
                  <div className="card-item">
                    İşlevsel düzeyi belirlemek, değişen fizyolojik kapasiteyi
                    göz önünde bulundurmak, ailenin beklentilerini belirlemek,
                    işlevin tekrar kazanılabileceğini bilmek, hareketsizliği
                    önlemek, Göreve özgün egzersiz programı üzerinde durmak,
                    programı basit tutmak başlıca rehabilitasyonun temel
                    ilkeleri arasındadır.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Geriatrik Hasta Nasıl Değerlendirilir?
                  </div>
                  <div className="card-item">
                    Her hasta özel değerlendirilmelidir. Fonksiyonel durumu,
                    bilişsel düzeyi, fizyolojik kısıtlılıkları, sosyoekonomik
                    düzeyi, ailevi düzeyi ve desteği, kültürel düzey ve
                    beklentileri ile özgün rehabilitasyon planına ihtiyaç
                    vardır. Bu değerlendirmeler sonrası hasta için uygun
                    hedefler belirlenmelidir.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Geriatrik Rehabilitasyon Süreci Nasıl İlerler?
                  </div>
                  <div className="card-item">
                    Eldeki mevcut veriler, tıbbi ve sosyo-ekonomik olanaklar ve
                    beklentiler çerçevesinde rehabilitasyon programı
                    oluşturulmalıdır. Mevcut durum, varılması planlanan hedefler
                    ve rehabilitasyon süreci hasta ve yakınları ile paylaşılarak
                    hedefler hakkında farklı beklentilerin oluşmasına engel
                    olunmalıdır.
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row g-0">
              <Link to="/hizmetlerimiz" className="btn btn-light btn-xl">
                Diğer hizmetlerimiz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
