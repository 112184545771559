import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Noro from "../../components/rehabilitation/Noro";
import CallToAction from "../../components/CallToAction"

export default function NoroPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <Noro />
      <CallToAction />
    </div>
  );
}
