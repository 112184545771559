import React from "react";

export default function About() {
  return (
    <div>
      <section className="page-section bg-primary" id="hakkimizda">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">Neden Fizyoterapistin?</h2>
              <hr className="divider divider-light" />
              <p className="text-white-75 mb-4">
                Fizyoterapistin ekibi, Türkiye'nin en ileri gelen
                üniversitelerinden mezun olmuş, alanında kendini yetiştirmiş, 
                <b> evde fizik tedavi konusunda tecrübeli</b>, işini seven,
                insanları seven, güler yüzlü fizyoterapistlerden oluşmaktadır.
                <br />
                <br />
                Tanısı konmuş hastanın herhangi bir hastane ya da klinik ortamı
                olmaksızın <b>kendi evinde</b>, rahat ve güvenli ortamında
                <b> uzman ekibimizle</b> kendisine <b>en uygun</b> ve
                <b> en doğru</b> fizik tedavi hizmetine ulaşmasını sağlıyoruz.
              </p>
              <br />
              <a className="btn btn-light btn-xl" href="#hizmetlerimiz">
                Hizmetlerimiz
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
