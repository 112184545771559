import React from "react";
import { Link } from "react-router-dom";

export default function Portfolio() {
  return (
    <div>
      <section className="page-section">
        <div id="hizmetlerimiz">
          <div className="container-fluid px-4 px-lg-5 text-center">
            <h2 className="text-center">Hizmetlerimiz</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="col-lg-3 col-sm-6">
                <Link
                  className="portfolio-box"
                  to="/norolojikrehabilitasyon"
                  title="Nörolojik Rehabilitasyon"
                >
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio/noro1.jpg")}
                    alt="..."
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50"></div>
                    <div className="project-name">Nörolojik Rehabilitasyon</div>
                  </div>
                </Link>
                <div className="project-description bg-primary text-white p-3">
                  Nörolojik Rehabilitasyon
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <Link
                  className="portfolio-box"
                  to="/ortopedikrehabilitasyon"
                  title="Ortopedik Rehabilitasyon"
                >
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio/orto1.jpg")}
                    alt="..."
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50"></div>
                    <div className="project-name">Ortopedik Rehabilitasyon</div>
                  </div>
                </Link>
                <div className="project-description bg-primary text-white p-3">
                  Ortopedik Rehabilitasyon
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <Link
                  className="portfolio-box"
                  to="/pediatrikrehabilitasyon"
                  title="Pediatrik Rehabilitasyon"
                >
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio/pediatric1.jpg")}
                    alt="..."
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50"></div>
                    <div className="project-name">Pediatrik Rehabilitasyon</div>
                  </div>
                </Link>
                <div className="project-description bg-primary text-white p-3">
                  Pediatrik Rehabilitasyon
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <Link
                  className="portfolio-box"
                  to="geriatrikrehabilitasyon"
                  title="Geriatrik Rehabilitasyon"
                >
                  <img
                    className="img-fluid"
                    src={require("../assets/img/portfolio/geriatric1.jpg")}
                    alt="..."
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50"></div>
                    <div className="project-name">Geriatrik Rehabilitasyon</div>
                  </div>
                </Link>
                <div className="project-description bg-primary text-white p-3">
                  Geriatrik Rehabilitasyon
                </div>
              </div>
            </div>
            <br />
            <Link className="btn btn-primary btn-xl" to="/hizmetlerimiz">
              Tüm hizmetlerimizi görmek için tıklayın
            </Link>
            <br />
            <br />
          </div>
        </div>
      </section>
    </div>
  );
}
