import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Orto from "../../components/rehabilitation/Orto";
import CallToAction from "../../components/CallToAction"

export default function OrtoPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <Orto />
      <CallToAction />
    </div>
  );
}
