import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDQ9dgX8csMS6NHfeefpvxWMqBd57LpsqQ",
  authDomain: "fizyoterapistinhasta.firebaseapp.com",
  projectId: "fizyoterapistinhasta",
  storageBucket: "fizyoterapistinhasta.appspot.com",
  messagingSenderId: "475179079219",
  appId: "1:475179079219:web:9ea5275dcea1a0f876b546",
  measurementId: "G-KN420T0HEQ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

const auth = getAuth(app);

export { db, auth };
