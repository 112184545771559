import React from "react";
import logo from "../assets/logos/fizyoterapistinlogo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Navbar({ isScrolled }) {
  const links = [
    { href: "/hakkimizda", text: "Hakkımızda" },
    { href: "/hizmetlerimiz", text: "Hizmetlerimiz" },
    { href: "/ekibimiz", text: "Ekibimiz" },
    { href: "/hastaformu", text: "Hasta Formu" },
    { href: "/iletisim", text: "İletişim" },
  ];

  const createNavItem = ({ href, text, className }, index) => (
    <li key={index} className="nav-item">
      <Link to={href} className={className + " nav-link"}>
        {text}
      </Link>
    </li>
  );

  return (
    <Container>
      <nav
        className={`flex ${
          isScrolled
            ? "scrolled navbar navbar-expand-lg fixed-top py-3"
            : "navbar navbar-expand-lg navbar-light fixed-top py-3"
        }`}
        id="mainNav"
      >
        <div className="container px-4 px-lg-5">
          <Link className="navbar-brand" to="/">
            <span>
              <img src={logo} alt="Fizyoterapistin" height="50" width="50" />
            </span>
            &nbsp;Fizyoterapistin
          </Link>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto my-2 my-lg-0">
              {links.map(createNavItem)}
            </ul>
          </div>
        </div>
      </nav>
    </Container>
  );
}

const Container = styled.div`
  .scrolled {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: #fff !important;
    color: #212529 !important;
    .navbar-brand {
      color: #212529 !important;
    }
    .navbar-brand:hover {
      color: #1082ce !important;
    }
    .navbar-nav .nav-item .nav-link {
      color: #212529 !important;
    }
    .navbar-nav .nav-item .nav-link:hover {
      color: #1082ce !important;
    }
  }
  .scrolled:hover {
    color: #1082ce !important;
  }
`;
