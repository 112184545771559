import { useState, createContext } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const MessageContext = createContext();

function Provider({ children }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [town, setTown] = useState("");

  function addAlert() {
    const newAlert = document.createElement("div");
    newAlert.className = "alert alert-success";
    newAlert.role = "alert";
    const newContent = document.createTextNode(
      "Mesajınız gönderildi. Vermiş olduğunuz telefon numarası yoluyla terapistimiz sizinle en yakın zamanda iletişime geçecektir."
    );
    newAlert.appendChild(newContent);
    const alert = document.getElementById("alert");
    alert.appendChild(newAlert);
    alert.style.display = "block";
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleTownChange = (e) => {
    setTown(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    addAlert();
    addNewMessage();
    setName("");
    setMessage("");
    setPhone("");
    setTown("");
  };

  const addNewMessage = async () => {
    // console.log(
    //   `name: ${name}, phone: ${phone}, town: ${town}, message: ${message},`
    // );

    const current = new Date();
    const showTime =
      current.getHours() +
      ":" +
      current.getMinutes() +
      ":" +
      current.getSeconds();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    const time = date + " " + showTime;

    const ref = collection(db, "messages");
    await addDoc(ref, {
      name: name,
      phone: phone,
      town: town,
      message: message,
      time: time,
    });
  };

  const values = {
    name,
    phone,
    town,
    message,
    handleNameChange,
    handlePhoneChange,
    handleTownChange,
    handleMessageChange,
    addAlert,
    submitHandler,
  };

  return (
    <MessageContext.Provider value={values}>{children}</MessageContext.Provider>
  );
}

export { Provider };

export default MessageContext;
