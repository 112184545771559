import React, {useState} from "react";
import Navbar from "../components/Navbar";
import Crew from "../components/Crew";
import CallToAction from "../components/CallToAction";
import Contact from "../components/Contact";

export default function CrewPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <Crew />
      <CallToAction />
      <Contact />
    </div>
  );
}
