import React, { useState } from "react";
import About from "../components/About";
import Appointment from "../components/Appointment";
import CallToAction from "../components/CallToAction";
import Contact from "../components/Contact";
import Masthead from "../components/Masthead";
import Navbar from "../components/Navbar";
import Portfolio from "../components/Portfolio";
import Sss from "../components/Sss";

export default function Home() {
  const [isScrolled, setIsScrolled] = useState(false);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <Masthead />
      <About />
      <Portfolio />
      <CallToAction />
      <Appointment />
      <Sss />
      <Contact />
    </div>
  );
}
