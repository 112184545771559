import React from "react";

export default function Crew() {
  return (
    <div>
      <section className="page-section">
        <div id="portfolio">
          <div className="container-fluid px-4 px-lg-5">
            <h2 className="text-center">Ekibimiz</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="col-lg-3 col-sm-6 p-1" id="emptyCol"></div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/furkankaya2.jpeg")}
                    className="team"
                    alt="Furkan Kaya"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Furkan Kaya</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/furkankuvel.jpg")}
                    className="team"
                    alt="Furkan Kuvel"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Furkan Kuvel</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1" id="emptyCol"></div>
            </div>
            <div className="row g-0">
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/minehanimdemirkiran1.jpeg")}
                    className="team"
                    alt="Mine Hanım Demirkıran"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4 className="font-size-card">Mine Hanım Demirkıran</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/suzandogan.png")}
                    className="team"
                    alt="Suzan Doğan"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Suzan Doğan</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/pinarbakir.png")}
                    className="team"
                    alt="Pınar Bakır"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Pınar Bakır</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/aleynasezen.png")}
                    className="team"
                    alt="Aleyna Sezen"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Aleyna Sezen</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/kaderatik.png")}
                    className="team"
                    alt="Kader Atik"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Kader Atik</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/ahmetatilla.jpeg")}
                    className="team"
                    alt="Ahmet Atilla"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Ahmet Atilla</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/havvakose.png")}
                    className="team"
                    alt="Havva Köse"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Havva Köse</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/man2.jpg")}
                    className="team"
                    alt="Abdullah Dülger"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Abdullah Dülger</h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 p-1">
                <div className="card bg-light">
                  <img
                    src={require("../assets/img/therapists/man2.jpg")}
                    className="team"
                    alt="Erol Arslan"
                  />
                  <div className="card-body bg-light text-primary text-center">
                    <h6>Fizyoterapist</h6>
                    <h4>Erol Arslan</h4>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
}
