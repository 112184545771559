import React from "react";
import { Link } from "react-router-dom";

export default function Pediatric() {
  return (
    <div>
      <section className="page-section bg-primary">
        <div id="portfolio">
          <div className="container-fluid px-4 px-lg-5">
            <h2 className="text-center text-white">Pediatrik Rehabilitasyon</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Nedir?</div>
                  <div className="card-item">
                    <b>Pediatrik rehabilitasyon</b>, çocuklarda var olan
                    kapasiteyi ortaya çıkarmak ve geliştirmek, tespit edilen
                    hastalıkları rehabilite etmek ve çocuğun bağımsız olarak
                    mümkün olan en yüksek düzeyde yaşamını sürdürmesini sağlamak
                    amacıyla uygulanan bir tedavi şeklidir.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Hangi Hastalar Pediatrik Rehabilitasyon Programına Alınır?
                  </div>
                  <div className="card-item">
                    <ul>
                      <li>Serebral Palsi (CP)</li>
                      <li>Spina Bifida</li>
                      <li>Spinal Musküler Atrofi (SMA)</li>
                      <li>Hidrosefali</li>
                      <li>Brakial Pleksus Yaralanmaları</li>
                      <li>Tortikollis</li>
                      <li>Riskli Bebek (Prematüre)</li>
                      <li>Musküler Distrofi</li>
                      <li>Riskli Bebek</li>
                      <li>Osgood-Schlatter Hastalığı</li>
                      <li>Kırık Rehabilitasyonu</li>
                      <li>Konjenital (Doğuştan) Anomaliler</li>
                      <li>Juvenil Artrit (Eklem iltihabı)</li>
                      <li>Ortopedik Bozukluklar</li>
                      <li>Omurga Deformiteleri (Skolyoz, Kifoz)</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Rehabilitasyona Ne Zaman Başlanmalı?
                  </div>
                  <div className="card-item">
                    Pediatrik rehabilitasyon ile daha etkili sonuçlar almak için
                    tedaviye erken başlanılması çok önemlidir. Bu noktada anne
                    babalara önemli görev düşmektedir. Çocuklarının
                    gelişimlerini yakında takip etmek ve karşılaştıkları
                    sorunları dikkate alarak doktora gitmeleri çok önemlidir.
                    Çocukların gelişim sürecinde dikkat edilmesi gereken
                    belirtiler ise şunlardır:
                    <ul>
                      <li>1 aylıkken başını sabit tutamaması</li>
                      <li>6 aylıkken kendi başına dönememesi</li>
                      <li>8 aylıkken desteksiz oturamaması</li>
                      <li>12 aylıkken tutunarak bile yürüyememesi</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Pediatrik Rehabilitasyon Süreci Nasıl İlerler?
                  </div>
                  <div className="card-item">
                    Rehabilitasyon programı, çocuklar için kapsamlı multi
                    disipliner rehabilitasyon bakımı hizmeti sunmaktadır. Amaç,
                    her bir çocuğun yeteneklerini en üst düzeye çıkarırken,
                    engellerin etkilerini en aza indirmektir. Bu, aile merkezli
                    bir ortamda sağlanan en yüksek kalitenin etkin
                    rehabilitasyonu ile gerçekleştirilir.
                    <br />
                    <br />
                    Bozulmanın doğasına ve çocuğun ihtiyaçlarına bağlı olarak,
                    tedavi çeşitli ortamlarda gerçekleşir ve mümkün olan en
                    yüksek seviyedeki fonksiyonu teşvik etmek için
                    rehabilitasyon profesyonellerinin çeşitli becerilerini
                    kullanır. Pediatrik rehabilitasyon ekibi, her bireyin öz
                    bakım, hareketlilik, iletişim, biliş ve sosyalleşmede tam
                    işlevsel bağımsızlığa erişmesine yardımcı olmayı hedefler.
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row g-0">
              <Link to="/hizmetlerimiz" className="btn btn-light btn-xl">
                Diğer hizmetlerimiz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
