import React, {useState} from "react";
import CallToAction from "../components/CallToAction";
import Navbar from "../components/Navbar";
import PortfolioFull from "../components/PortfolioFull";

export default function PortfolioPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <PortfolioFull />
      <CallToAction />
    </div>
  );
}
