import React from "react";
import headerImage from "../assets/img/header.jpg";
import styled from "styled-components";

export default function Masthead() {
  return (
    <Container>
      <header
        className="masthead"
        style={{
          background: `linear-gradient(
            to bottom,
            rgba(92, 77, 66, 0.8) 0%,
            rgba(92, 77, 66, 0.8) 100%
          ), url(${headerImage})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "10rem",
          paddingBottom: "calc(10rem - 4.5rem)",
        }}
      >
        <div className="container px-4 px-lg-5 h-100">
          <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-8 align-self-end">
              <h1 className="text-white font-weight-bold">
                Fizyoterapistin Yanına Gelsin
              </h1>
              <hr className="divider" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <p className="text-white-75 mb-5">
                Fizyoterapistinizi bulmak artık hiç zor değil. <br />
                Tek tıkla size özel fizyoterapistiniz yanınıza gelsin. <br />
                <br />
                {/* İlk değerlendirme, uzman değerlendirme ekibimizce görüntülü
                görüşme yoluyla ÜCRETSİZ olarak gerçekleştirilir. */}
                Hasta formunu doldurun, en uygun terapist sizinle iletişime
                geçsin.
                <br></br>WhatsApp yoluyla da hızlıca bilgi edinebilirsiniz.
              </p>
              <a className="btn btn-primary btn-xl" href="#randevu">
                Hemen bilgi almak için tıklayın
              </a>
              <br />
              <br />
              <a className="btn btn-primary btn-xl" href="#hakkimizda">
                Daha fazlası için
              </a>
            </div>
          </div>
        </div>
      </header>
    </Container>
  );
}

const Container = styled.div``;
