import React from "react";

export default function MessageList({ messages }) {
  return (
    <div>
      <h4>Yeni Mesajlar</h4>
      <ul className="allMessages">
        {messages.map((message) => (
          <li key={message.id} className="mb-2">
            <div>İsim: {message.name}</div>
            <div>Telefon Numarası: {message.phone}</div>
            <div>İlçe: {message.town}</div>
            <div>Mesaj: {message.message}</div>
            <div>Gönderme Tarihi: {message.time}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
