import React from "react";
import { Link } from "react-router-dom";

export default function Sss() {
  const wpurl =
    "https://api.whatsapp.com/send/?phone=905353970454&text&type=phone_number&app_absent=0";

  return (
    <div>
      <section className="page-section bg-primary" id="sss">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">
                <Link to="/sss">Sıkça Sorulan Sorular</Link>
              </h2>
              <hr className="divider divider-light" />
              <br />
              <div className="row g-0">
                <div className="col-lg-6 col-sm-6 px-1">
                  <h5 className="text-white">
                    Evde Fizyoterapi Nedir, Nasıl Uygulanır?
                  </h5>
                  <p className="text-white-75 pt-1">
                    Hastanın kendi evinde
                    <span className="text-white"> en uygun</span> ve
                    <span className="text-white"> en doğru</span> fizik tedavi
                    hizmetini almasıdır. Belirli
                    <span className="text-white"> randevu günü</span> ve
                    <span className="text-white"> saatinde</span> evinize
                    fizyoterapist gelir. Fizyoterapistin değerlendirmesi sonrası
                    uygun
                    <span className="text-white"> tedavi-seans programı </span>
                    oluşturulur ve tedaviye başlanır.
                  </p>
                </div>
                <div className="col-lg-6 col-sm-6 px-1">
                  <h5 className="text-white">
                    İlk Değerlendirme Seansı Nasıl Olacak?
                  </h5>
                  <p className="text-white-75 pt-1">
                    İlk değerlendirme seansı
                    <span className="text-white"> uzman değerlendirme ekibimizce görüntülü görüşme yoluyla
                      ÜCRETSİZ </span>
                    şekilde yapılabildiği gibi
                    <span className="text-white"> fizyoterapistinizle yüz yüze ilk seans </span>
                    olacak şekilde de yapılabilir.
                  </p>
                </div>
                <div className="col-lg-6 col-sm-6 px-1">
                  <h5 className="text-white">
                    Evde Fizyoterapi Ücreti Ne Kadar?
                  </h5>
                  <p className="text-white-75 pt-1">
                    Tedavi ücreti; hastanın
                    <span className="text-white"> tanısı, durumu, lokasyonu, isteklerine </span>
                    göre belirlenir. Ücret seans başı, haftalık ya da aylık
                    ödeme şeklinde alınabilmektedir.
                  </p>
                </div>
                <div className="col-lg-6 col-sm-6 px-1">
                  <h5 className="text-white">Nasıl İletişime Geçebilirim?</h5>
                  <p className="text-white-75 pt-1">
                    <Link to="/hastaformu"> Hasta iletişim formu</Link>
                    ,<br></br>
                    <a href={wpurl} target="_blank" rel="noopener noreferrer"> WhatsApp iletişim hattı </a>
                    <br></br>ve
                    <a href="#contact"> irtibat numaralarımız </a>
                    aracılığıyla iletişime gerçekleştirebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
