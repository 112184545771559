import React, { useContext } from "react";
import MessageContext from "../context/messages";

export default function Appointment() {
  const {
    name,
    phone,
    town,
    message,
    handleNameChange,
    handlePhoneChange,
    handleTownChange,
    handleMessageChange,
    submitHandler,
  } = useContext(MessageContext);

  return (
    <div>
      <section className="page-section" id="appointment">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <h2 className="mt-0">Hasta İletişim Formu</h2>
              <hr className="divider" />
              <p className="text-muted mb-4">
                Siz mesajınızı bırakın, terapistimiz sizinle iletişime geçsin.
              </p>
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
            <div className="col-lg-6">
              {/* <!-- Contact Form --> */}
              <form id="contactForm" onSubmit={submitHandler}>
                {/* <!-- Name input--> */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="İsim Soyisim"
                    required
                    value={name || ""}
                    onChange={handleNameChange}
                  />
                  <label htmlFor="name">İsim</label>
                </div>
                {/* <!-- Phone number input--> */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="phone"
                    type="tel"
                    placeholder="Telefon Numarası"
                    required
                    name="phone"
                    value={phone || ""}
                    onChange={handlePhoneChange}
                  />
                  <label htmlFor="phone">Telefon Numarası</label>
                </div>
                {/* <!-- İlçe  --> */}
                <div className="form-floating mb-3">
                  <select
                    className="form-select pt-4"
                    id="town"
                    name="town"
                    required
                    value={town || ""}
                    onChange={handleTownChange}
                  >
                    <option label="Seçiniz"></option>
                    {/* <option>Adalar</option> */}
                    {/* <option>Arnavutköy</option> */}
                    <option>Ataşehir</option>
                    {/* <option>Avcılar</option> */}
                    <option>Bağcılar</option>
                    <option>Bahçelievler</option>
                    <option>Bakırköy</option>
                    {/* <option>Başakşehir</option> */}
                    <option>Bayrampaşa</option>
                    <option>Beşiktaş</option>
                    {/* <option>Beykoz</option> */}
                    {/* <option>Beylikdüzü</option> */}
                    <option>Beyoğlu</option>
                    {/* <option>Büyükçekmece</option> */}
                    {/* <option>Çatalca</option> */}
                    {/* <option>Çekmeköy</option> */}
                    <option>Esenler</option>
                    {/* <option>Esenyurt</option> */}
                    <option>Eyüpsultan</option>
                    <option>Fatih</option>
                    <option>Gaziosmanpaşa</option>
                    <option>Güngören</option>
                    <option>Kadıköy</option>
                    {/* <option>Kağıthane</option> */}
                    <option>Kartal</option>
                    {/* <option>Küçükçekmece</option> */}
                    <option>Maltepe</option>
                    <option>Pendik</option>
                    {/* <option>Sancaktepe</option> */}
                    <option>Sarıyer</option>
                    {/* <option>Silivri</option> */}
                    {/* <option>Sultanbeyli</option> */}
                    {/* <option>Sultangazi</option> */}
                    {/* <option>Şile</option> */}
                    <option>Şişli</option>
                    <option>Tuzla</option>
                    <option>Ümraniye</option>
                    <option>Üsküdar</option>
                    <option>Zeytinburnu</option>
                  </select>
                  <label htmlFor="disease">İlçe</label>
                  <sub className="text-muted text-center ml-3 mt-1">
                    Yalnızca seçeneklerdeki yerleşim yerlerine evde fizik tedavi
                    hizmeti sunabilmekteyiz.
                  </sub>
                </div>
                {/* <!-- Message  --> */}
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    type="text"
                    name="message"
                    placeholder="Mesajınız"
                    style={{ height: "10rem" }}
                    required
                    value={message || ""}
                    onChange={handleMessageChange}
                  ></textarea>
                  <label htmlFor="message">Mesajınız</label>
                  <sub className="text-muted text-center ml-3 mt-1">
                    Hastanın durumuyla alakalı detaylı bilgi vermeniz, terapiden beklentilerinizi belirtmeniz iletişimi hızlandıracaktır.
                  </sub>
                </div>
                {/* <!-- Submit Button--> */}
                <div className="d-grid">
                  <button
                    className="btn btn-primary btn-xl"
                    id="submitButton"
                    type="submit"
                    name="submitButton"
                    value="Send Email"
                  >
                    Gönder
                  </button>
                </div>
                <br />
                <div id="alert" style={{ display: "none" }}></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
