import React from "react";
import { Link } from "react-router-dom";

export default function Noro() {
  return (
    <div>
      <section className="page-section bg-primary">
        <div id="portfolio">
          <div className="container-fluid px-4 px-lg-5">
            <h2 className="text-center text-white">Nörolojik Rehabilitasyon</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Nedir?</div>
                  <div className="card-item">
                    Sinir sistemi bozuklukları veya nörolojik hastalıkları olan
                    hastaların tedavisine yardımcı olmak için uygulanan
                    fizyoterapi alanına
                    <b>nörolojik rehabilitasyon</b> denir. Rehabilitasyon,
                    işlevi artırmayı, semptomları azaltmayı ve hastanın yaşam
                    kalitesini iyileştirmeyi amaçlar.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Hangi Hastalar Nörolojik Rehabilitasyon Programına Alınır?
                  </div>
                  <div className="card-item">
                    <ul>
                      <li>İnme (Felç) geçirmiş hastalar</li>
                      <li>Multipl Skleroz (MS)</li>
                      <li>Parkinson</li>
                      <li>Spinal Kord Yaralanmaları</li>
                      <li>Ataksi</li>
                      <li>Vestibüler Disfonksiyon</li>
                      <li>Nöromusküler Hastalıklar</li>
                      <li>Travmatik Beyin Yaralanmaları</li>
                      <li>Periferik Sinir Hastalıkları</li>
                      <li>Beyin Tümörü</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Rehabilitasyona Ne Zaman Başlanmalı?
                  </div>
                  <div className="card-item">
                    Rehabilitasyon hastalığın tanısı konulduktan veya hasta
                    hayati tehlikeyi atlattıktan sonra mümkün olan en erken
                    dönemde başlatılmalıdır. Hastanın fonksiyonel seviyesi
                    hakkında bilgi edinmek amacıyla fizyoterapist tarafından
                    yapılan değerlendirmenin özellikleri, tanıya, hastanın
                    yaşına, algılama durumuna göre farklılıklar gösterse de
                    temel olarak birbirine benzemektedir.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Nörolojik Rehabilitasyon Süreci Nasıl İlerler?
                  </div>
                  <div className="card-item">
                    Rehabilitasyonun amacı hastayı mümkün olan en aktif duruma
                    ulaştırmak,fonksiyonel bağımsızlığını elde etmesine yardımcı
                    olmaktır. Etkili bir rehabilitasyon programı iyi
                    yapılandırılmış ve hastanın kendi çevresel koşulları
                    icindeki fonksiyonel durumunu yansıtan bir değerlendirme ile
                    başlar. Hasta değerlendirmenin merkezine alınır ve hastaya
                    özel değerlendirme yöntemleri seçilir. Değerlendirme
                    sonuçlarına göre kısa ve uzun dönem hedefler belirlenir. Bu
                    hedefler doğrultusunda hastaya en uygun tedavi programına
                    başlanır.
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row g-0">
              <Link to="/hizmetlerimiz" className="btn btn-light btn-xl">
                Diğer hizmetlerimiz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
