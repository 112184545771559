import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";

function DashboardLayout() {
  return (
    <div>
      <Outlet />

      <Footer />
    </div>
  );
}

export default DashboardLayout;
