import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Geriatric from "../../components/rehabilitation/Geriatric";
import CallToAction from "../../components/CallToAction"

export default function GeriatricPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <Geriatric />
      <CallToAction />
    </div>
  );
}
