import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  const telkaya = "tel:+905522067423";
  const telkuvel = "tel:+905353970454";

  return (
    <div>
      <section className="page-section">
        <div id="contact">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-center">İletişim</h2>
                <hr className="divider" />
                <div className="col-lg align-self-baseline bg-primary p-4 rcorners1">
                  <p className="text-white-75 mb-4 p-1">
                    İletişim Numaraları:&nbsp;<br/>
                    <a
                      href={telkuvel}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      +905353970454
                    </a>
                    &nbsp;&nbsp;
                    <a href={telkaya} target="_blank" rel="noopener noreferrer">
                      +905522067423{" "}
                    </a>
                    <br />
                    E-mail:&nbsp;
                    <a href="mailto:info@fizyoterapistin.com">
                      info@fizyoterapistin.com
                    </a>
                  </p>
                  <div className="text-white-75 mb-4">
                    İletişim ve sorularınız için
                    <b> telefon numaramızı</b> arayabilir,
                    <b> WhatsApp</b> uygulamasından,{" "}
                    <b> e-posta adresimizden</b> ve diğer
                    <b> sosyal medya hesaplarımızdan </b>
                    bize ulaşabilirsiniz. Websitemizdeki
                    <Link to="/hastaformu"> hasta iletişim formunu </Link>
                    doldurarak da hızlıca mesajınızı bırakabilirsiniz.
                    Telefon numaralarımıza hafta içi ve hafta sonu
                    <b> 10.00 &#45; 22.00 </b>
                    arasında ulaşabilirsiniz.
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/people/Fizyoterapistin/100088613554983/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-facebook fa-2x text-white"></i>
                    </a>
                    &nbsp;
                    <a
                      href="https://www.instagram.com/fizyoterapistiin/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-instagram fa-2x text-white"></i>
                    </a>
                    &nbsp;
                    <a
                      href="https://www.twitter.com/fzyoterapistin/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-square-twitter fa-2x text-white"></i>
                    </a>
                    &nbsp;
                    <a
                      href="https://www.linkedin.com/company/fizyoterapistincom/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin fa-2x text-white"></i>
                    </a>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
