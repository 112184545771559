import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import CrewPage from "./pages/CrewPage";
import Home from "./pages/Home";
import ContactPage from "./pages/ContactPage";
import PortfolioPage from "./pages/PortfolioPage";
import AppointmentPage from "./pages/AppointmentPage";
import SssPage from "./pages/SssPage";
import NoroPage from "./pages/rehabilitationpages/NoroPage";
import OrtoPage from "./pages/rehabilitationpages/OrtoPage";
import PediatricPage from "./pages/rehabilitationpages/PediatricPage";
import GeriatricPage from "./pages/rehabilitationpages/GeriatricPage";
import SmoothScroll from "./components/SmoothScroll";
import DashboardLayout from "./layouts/DashboardLayout";
import TherapistPage from "./pages/therapists/TherapistPage";
import LoginPage from "./pages/therapists/LoginPage";

export default function App() {
  return (
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<DashboardLayout />}>
              <Route exact path="/" index element={<Home />} />
              <Route exact path="/hakkimizda" element={<AboutPage />} />
              <Route exact path="/hizmetlerimiz" element={<PortfolioPage />} />
              <Route exact path="/ekibimiz" element={<CrewPage />} />
              <Route exact path="/hastaformu" element={<AppointmentPage />} />
              <Route exact path="/therapist" element={<TherapistPage />} />
              <Route exact path="/therapistlogin" element={<LoginPage />} />
              <Route exact path="/iletisim" element={<ContactPage />} />
              <Route exact path="/sss" element={<SssPage />} />
              <Route
                exact
                path="/norolojikrehabilitasyon"
                element={<NoroPage />}
              />
              <Route
                exact
                path="/ortopedikrehabilitasyon"
                element={<OrtoPage />}
              />
              <Route
                exact
                path="/pediatrikrehabilitasyon"
                element={<PediatricPage />}
              />
              <Route
                exact
                path="/geriatrikrehabilitasyon"
                element={<GeriatricPage />}
              />
            </Route>
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
  );
}
