import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/config";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Giriş başarılı");
      navigate("/therapist");
    } catch (err) {
      console.log(err);
    }
  };

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) navigate("/therapist");
  });

  return (
    <div className="row gx-4 gx-lg-5 justify-content-center mb-5 mt-5">
      <div className="col-lg-6">
        <form id="loginForm">
          <h3>Terapist Girişi</h3>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              required
              value={email || ""}
              onChange={handleEmailChange}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="password"
              name="password"
              type="password"
              placeholder="Şifre"
              required
              value={password || ""}
              onChange={handlePasswordChange}
            />
            <label htmlFor="password">Şifre</label>
          </div>
          <button
            className="btn btn-primary btn-xl"
            id="submitButton"
            type="submit"
            name="submitButton"
            onClick={handleLogIn}
          >
            Giriş
          </button>
          <a href="/" className="btn btn-primary btn-xl">
            Anasayfa
          </a>
        </form>
      </div>
    </div>
  );
}
