import React, { useState } from "react";
import About from "../components/About";
import CallToAction from "../components/CallToAction";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";

export default function AboutPage() {
  const [isScrolled, setIsScrolled] = useState(true);

  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? true : true);
    return () => (window.onscroll = null);
  };
  return (
    <div>
      <Navbar isScrolled={isScrolled} />
      <About />
      <Portfolio />
      <CallToAction />
      <Contact />
    </div>
  );
}
