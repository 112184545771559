import React from "react";
import { Link } from "react-router-dom";

export default function CallToAction() {
  const wpurl =
    "https://api.whatsapp.com/send/?phone=905353970454&text&type=phone_number&app_absent=0";

  return (
    <div>
      <section className="page-section bg-dark text-white" id="randevu">
        <div className="container px-4 px-lg-5 text-center">
          <h2 className="mb-4">Hemen Fizyoterapistini Bul!</h2>
          <p className="text-white-75 mb-4">
            ÖZEL DEĞERLENDİRME EKİBİYLE <br />
            <br />
            HASTAYA ÖZEL, KENDİ RAHAT ORTAMINDA
            <br />
            <br />
            FİZİK TEDAVİ HİZMETİ
            <br />
          </p>
          <Link
            className="btn btn-danger btn-xl"
            id="btn-appointment"
            to="/hastaformu"
          >
            Hasta Formu
          </Link>
          <br />
          <br />
          <a
            className="btn btn-light btn-xl bg-wp text-white"
            id="wp-btn"
            href={wpurl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="py-5">
              <i className="fa-brands fa-whatsapp fa-xl"></i>
            </span>
            &nbsp; WhatsApp İletişim
          </a>
        </div>
      </section>
    </div>
  );
}
