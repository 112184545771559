import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="bg-light py-5">
        <div className="container px-4 px-lg-5">
          <div className="small text-center text-muted">
            Copyright &copy; 2022 - Fizyoterapistin
            <br />
            <br />
            <a
              href="https://www.facebook.com/people/Fizyoterapistin/100088613554983/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-square-facebook fa-2x"></i>
            </a>&nbsp;
            <a
              href="https://www.instagram.com/fizyoterapistiin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-square-instagram fa-2x"></i>
            </a>&nbsp;
            <a href="https://www.twitter.com/fzyoterapistin/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-square-twitter fa-2x"></i>
            </a>&nbsp;
            <a
              href="https://www.linkedin.com/company/fizyoterapistincom/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin fa-2x"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
