import React from "react";
import { Link } from "react-router-dom";

export default function Orto() {
  return (
    <div>
      {" "}
      <section className="page-section bg-primary">
        <div id="portfolio">
          <div className="container-fluid px-4 px-lg-5">
            <h2 className="text-center text-white">Ortopedik Rehabilitasyon</h2>
            <hr className="divider" />
            <div className="row g-0">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Nedir?</div>
                  <div className="card-item">
                    Ortopedik rehabilitasyon, amacı kas-iskelet sistemi
                    kısıtlanmalarını azaltmak veya önlemek, travma, hastalık
                    veya ameliyattan kaynaklanan ağrıyı hafifletmek ve
                    fonksiyonel işlevselliği arttırmak olan iyileşmeye yönelik
                    terapötik bir yaklaşımdır.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Hangi Hastalar Ortopedik Rehabilitasyon Programına Alınır?
                  </div>
                  <div className="card-item">
                    <ul>
                      <li>Osteoartrit</li>
                      <li>Patellofemoral Ağrı Sendromu</li>
                      <li>Kırıklar</li>
                      <li>Lomber Disk Herniasyonu</li>
                      <li>Artritler</li>
                      <li>Ön Çapraz Bağ Yaralanmaları</li>
                      <li>Menisküs Yaralanmaları</li>
                      <li>Donuk Omuz</li>
                      <li>Subakromial İmpingement Sendromu</li>
                      <li>Lateral Epikondilit</li>
                      <li>Karpal Tünel Sendromu</li>
                      <li>Rotator Manşet Yırtığı</li>
                      <li>Omuz Çıkığı</li>
                      <li>Omuz İnstabilitesi (Bankart Lezyonu)</li>
                      <li>Skapular Diskinezi</li>
                      <li>Sert Dirsek</li>
                      <li>Total Kalça Artroplastisi</li>
                      <li>Femoroasetabuler Sıkışma</li>
                      <li>Plantar Fasiit</li>
                      <li>Hamstring Yaralanmaları</li>
                      <li>Aşil Tendinopatisi</li>
                      <li>Spinal Stenoz</li>
                      <li>Skolyoz</li>
                      <li>Tortikollis</li>
                      <li>Ampütasyon</li>
                      <li>Sporcu Yaralanmaları</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Ortopedik Rehabilitasyonun Amacı Nedir?
                  </div>
                  <div className="card-item">
                    Rehabilitasyonun amacı, hastanın kaybettiği
                    fonksiyonelliğini kazanmasını ve günlük yaşam aktivitelerine
                    olabildiğince hızlı ve etkili bir şekilde geri dönmesini
                    sağlamaktır.
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-title">
                    Ortopedik Rehabilitasyon Süreci Nasıl İlerler?
                  </div>
                  <div className="card-item">
                    Etkili bir rehabilitasyon programı iyi yapılandırılmış ve
                    hastanın kendi çevresel koşulları icindeki fonksiyonel
                    durumunu yansıtan bir değerlendirme ile başlar. Hasta
                    değerlendirmenin merkezine alınır ve hastaya özel
                    değerlendirme yöntemleri seçilir. Değerlendirme sonuçlarına
                    göre kısa ve uzun dönem hedefler belirlenir. Bu hedefler
                    doğrultusunda hastaya en uygun tedavi programına başlanır.
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row g-0">
              <Link to="/hizmetlerimiz" className="btn btn-light btn-xl">
                Diğer hizmetlerimiz
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
