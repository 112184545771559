import { onAuthStateChanged, signOut } from "firebase/auth";
import React from "react";
import { useContext } from "react";
import MessageList from "../../components/therapists/messages/MessageList";
import MessageContext from "../../context/messages";
import { auth } from "../../firebase/config";
import useCollection from "../../hooks/useCollection";
import { Link, useNavigate } from "react-router-dom";

export default function TherapistPage() {
  const { newMessage } = useContext(MessageContext);

  const navigate = useNavigate();
  onAuthStateChanged(auth, (currentUser) => {
    if (!currentUser) navigate("/therapistlogin");
  });

  const { documents: messages } = useCollection("messages");
  return (
    <div className="row gx-4 gx-lg-5 justify-content-center mb-5 mt-5">
      <div className="col-lg-6">
        {messages && <MessageList messages={messages} />}
        <br></br>
        <button
          className="btn btn-primary btn-xl"
          id="submitButton"
          type="submit"
          name="submitButton"
          onClick={() => signOut(auth)}
        >
          Çıkış
        </button>
        <a href="/" className="btn btn-primary btn-xl">
          Anasayfa
        </a>
      </div>
    </div>
  );
}
